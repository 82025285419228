
import {
    apiEngineList,
    apiSettingsExpressTemuRegionEdit,
    apiSettingsExpressTemuRegionAdd,
    apiSettingsExpressTemuRegionDetail,
    apiSettingsExpressTemuEngineList
} from '@/api/setting'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PageMode } from '@/utils/type'

@Component
export default class LogisticsEdit extends Vue {
    form: any = {
        region_id: '',
        region_ch_name: '',
        region_en_name: '',
        id: ''
    }
    formData: any = []
    engineList: any = []
    temuEngineList: any = []
    type = ''
    mode = 'add'

    handleSave() {

        let params: any = {}
        if (this.mode == 'edit') {
            apiSettingsExpressTemuRegionEdit(this.form).then(res => {
                this.$message.success('编辑成功')
            })
        } else {
            apiSettingsExpressTemuRegionAdd(this.form).then(res => {
                this.$message.success('新增成功')
            })
        }

    }

    changeType() { }

    async created() {
        const query: any = this.$route.query
        if (query.mode) this.mode = query.mode

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            console.log(this.form.id)
            apiSettingsExpressTemuRegionDetail({ id: this.form.id }).then(res => {
                this.form.region_id = res.region_id
                this.form.region_ch_name = res.region_ch_name
                this.form.region_en_name = res.region_en_name
            })
        }
    }
}
